import { setCookie, getCookie } from '@/js/tool.js'

export default {
  computed: {
    // 用户id
    userId() {
      return getCookie('id')
    }
  },
  methods: {
    // 设置 cookie
    setCookie,
    // 获取 cookie
    getCookie,

    // 获取浏览器信息
    getBrowser(n) {
      let ua = navigator.userAgent.toLowerCase(),
        s,
        name = '',
        ver = 0

      // 探测浏览器
      ;(s = ua.match(/msie ([\d.]+)/))
        ? _set('ie', _toFixedVersion(s[1]))
        : (s = ua.match(/firefox\/([\d.]+)/))
        ? _set('firefox', _toFixedVersion(s[1]))
        : (s = ua.match(/chrome\/([\d.]+)/))
        ? _set('chrome', _toFixedVersion(s[1]))
        : (s = ua.match(/opera.([\d.]+)/))
        ? _set('opera', _toFixedVersion(s[1]))
        : (s = ua.match(/version\/([\d.]+).*safari/))
        ? _set('safari', _toFixedVersion(s[1]))
        : 0

      function _toFixedVersion(ver, floatLength) {
        ver = ('' + ver).replace(/_/g, '.')
        floatLength = floatLength || 1
        ver = String(ver).split('.')
        ver = ver[0] + '.' + (ver[1] || '0')
        ver = Number(ver).toFixed(floatLength)
        return ver
      }

      function _set(bname, bver) {
        name = bname
        ver = bver
      }

      return n == 'n' ? name : n == 'v' ? ver : name + ver
    },

    // 将个人信息保存在 cookies 中
    saveUserInfo(data) {
      setCookie('nickname', data.nickname ? data.nickname : '')
      setCookie('mobile', data.mobile)
      setCookie('email', data.email)
      setCookie('isLawyer', data.isLawyer)
      setCookie('avatar', data.avatar)
      setCookie('lawfirmname', data.lawfirmname)
      setCookie('gender', data.gender)
      setCookie('realname', data.realname ? data.realname : '')
      setCookie('token', data.token ? data.token : '')
      setCookie('payPassword', data.payPassword ? data.payPassword : '') // 是否设置支付密码
      setCookie('status', data.status ? data.status : '') // 律师认证状态
      setCookie('areaCode', data.areaCode ? data.areaCode : '') // 区号
      setCookie('countryId', data.countryId ? data.countryId : '') // 国别ID
      setCookie('id', data.id)
      setCookie('tenantCode', data.tenantCode)
    }
  }
}
