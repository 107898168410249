export default {
  word1001: 'English',
  word1002: 'E-mail',
  word1003: 'Password',
  word1004: 'Forget password？',
  word1005: 'Login',
  word1006: 'Register',
  word1007: 'User Agreement',
  word1008: 'Privacy',
  word1009: 'About Us',
  word1010: 'Account cannot be empty',
  word1011: 'Password cannot be empty',
  word1012: 'The account has been frozen',
  word1013: 'Account or password error',
  word1014: 'Successful Login',
  word1015: 'Nickname',
  word1016: 'Input numbers on the right',
  word1017: 'Get the Code',
  word1018: 'Repeat the password',
  word1019: 'Cannot be blank',
  word1020: 'Incorrect Verification Code',
  word1021: 'Passwords are not the same',
  word1022: 'Cannot be blank',
  word1023: 'Cannot be blank',
  word1024: 'Cannot be blank',
  word1025: ' s to retry',
  word1026: 'Registration succeeded',
  word1027: 'The verification code from E-mail',
  word1028: 'Cannot be blank',
  word1029: 'New Password',
  word1030: 'Change Password',
  word1031: 'Cannot be blank',
  word1032: 'Modification Successful',
  word1033: 'The account has been disabled'
}
