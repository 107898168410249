export default {
  word1001: 'Bahasa',
  word1002: 'Kotak surat',
  word1003: 'kode',
  word1004: 'Lupa password？',
  word1005: 'Log masuk',
  word1006: 'pendaftaran',
  word1007: 'Perjanjian pengguna',
  word1008: 'Privasi pribadi',
  word1009: 'Tentang kita',
  word1010: 'Nomor rekening tidak bisa kosong',
  word1011: 'Password tidak bisa kosong',
  word1012: 'Nomor rekening telah dibekukan',
  word1013: 'Nomor rekening atau kata sandi yang salah',
  word1014: 'Berhasil masuk',
  word1015: 'Nama panggilan',
  word1016: 'Masukkan nomor kanan',
  word1017: 'Dapatkan captcha',
  word1018: 'ulangi',
  word1019: 'Nomor tidak boleh kosong',
  word1020: 'Kode otentikasi salah',
  word1021: 'Dua kali sandi yang tidak cocok',
  word1022: 'Nama panggilan tidak bisa kosong',
  word1023: 'Kotak surat tidak bisa kosong',
  word1024: 'Mengulangi sandi tidak bisa kosong',
  word1025: ' detik setelah coba',
  word1026: 'Pendaftaran berhasil',
  word1027: 'Kode verifikasi email',
  word1028: 'Kode otentikasi tidak bisa kosong',
  word1029: 'Kata sandi baru',
  word1030: 'Ubah sandi',
  word1031: 'Password baru tidak bisa kosong',
  word1032: 'Revisi berhasil',
  word1033: 'Akun itu telah dinonaktifkan'
}
